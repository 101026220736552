.r1pz6v5x{margin:auto;overflow-y:auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.g1uzz3b4{display:-ms-grid;display:grid;background:white;position:relative;overflow-y:auto;max-height:100%;padding:0.5rem;border-radius:0.75rem;-ms-grid-columns:1fr;grid-template-columns:1fr;-ms-grid-auto-rows:auto;grid-auto-rows:auto;grid-template-areas:'image' 'title' 'info';gap:1rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}@media screen and (width > 500px){.g1uzz3b4{-ms-grid-columns:clamp(150px, 250px, 300px) 1fr;grid-template-columns:clamp(150px, 250px, 300px) 1fr;-ms-grid-rows:auto 1fr;grid-template-rows:auto 1fr;width:100%;grid-template-areas:'image title' 'image info';}}
.i195xmkk{grid-area:image;position:relative;margin:auto;width:100%;min-height:150px;max-height:175px;background-color:#F1F1F2;border-radius:0.5rem;overflow:hidden;}.i195xmkk img{object-fit:cover;width:100%;}
.bvj1jnl{position:absolute;bottom:1rem;right:1rem;}
.ti6eonx{grid-area:title;}
.ntufvxv{font-weight:600;}
.d15ant8i{font-size:14px;font-weight:600;color:#757577;}
.i1teh5vx{grid-area:info;}
.a1h8otx8{font-size:14px;font-weight:500;color:#C3CBD9;-webkit-text-decoration:underline;text-decoration:underline;}.a1h8otx8:hover{color:#757577;}
.ccd62hd{position:absolute;top:0.5rem;right:0.5rem;}
